import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useFela } from 'react-fela';
import logger from 'utils/logger';
import { useSelector } from 'react-redux';
import { useI18n } from 'components/I18n';
import { getAdyenDropInStyles } from './adyen-dropin-styles';
import { AdyenDropInManager, DropInLocales, FlowTypes } from './adyen-dropin-manager';
import { OFFER_TYPES } from 'utils/constants';

function AdyenDropInComponents(props) {
  const {
    packageId,
    originalOfferId,
    promoCode,
    price,
    currency,
    returnPath,
    bypassPaymentMethod,
    onError,
    flowType = FlowTypes.PAYMENT,
    offerType,
  } = props;

  const { theme, renderer } = useFela();
  const i18n = useI18n();

  const apolloClient = useApolloClient();

  const locale = useSelector(state => state.settings.l10n.language);

  // render custom styles for adyen drop-in components
  React.useEffect(() => {
    renderer.renderStatic(getAdyenDropInStyles(theme));
  }, []);

  const domRef = React.useRef();

  React.useEffect(() => {
    const manager = new AdyenDropInManager(apolloClient, {
      packageId,
      originalOfferId,
      promoCode,
      price,
      currency,
      returnUrl: `${window.location.origin}${returnPath}`,
      onError,
      flowType,
      locale: DropInLocales[locale],
      i18n,
      theme,
      offerType,
    });

    if (bypassPaymentMethod) {
      manager.bypassPaymentMethod().catch((e) => {
        logger.error('Failed to bypass checkout', e);
        onError(e);
      });
    } else {
      manager.renderComponent(domRef.current).catch((e) => {
        logger.error('Failed to init adyen drop-in', e);
        onError(e);
      });
    }
  }, [price, promoCode]);

  return (
    <div
      id="adyen-dropin-components"
      ref={domRef}
      className="notranslate"
    />
  );
}
AdyenDropInComponents.propTypes = {
  packageId: PropTypes.string,
  originalOfferId: PropTypes.string,
  promoCode: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  returnPath: PropTypes.string.isRequired,
  bypassPaymentMethod: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  flowType: PropTypes.oneOf([FlowTypes.SETUP, FlowTypes.PAYMENT]),
  offerType: PropTypes.oneOf(Object.values(OFFER_TYPES)),
};

export default React.memo(AdyenDropInComponents);
