import { Query } from './location';

export function normalizeQueryParams(query: Query | undefined | null): Query {
  const result: Query = {};

  if (!query) {
    return result;
  }

  for (const [key, value] of Object.entries(query)) {
    if (value === true || value === 'true') {
      result[key] = null;
      continue;
    }

    if (value === false || value === 'false') {
      result[key] = undefined;
      continue;
    }

    result[key] = value;
  }

  return result;
}
