import createComponent from 'styles/fela/createComponent';
import { ItemWrapper } from 'components/HorizontalScroll/HorizontalScroll';
import { maxWidth, minWidth, widthRange } from 'styles/fela/mixins';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';

export const ArrowContainer = createComponent(({ theme, position, isVisible }) => ({
  position: 'absolute',
  top: 0,
  userSelect: 'none',
  cursor: 'pointer',
  zIndex: '10',
  height: '100%',
  visibility: 'hidden', // see ScrollableContainer
  display: 'flex',
  justifyContent: 'center',
  ':before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    transition: '0.2s ease-in-out opacity',
    opacity: 0.8,
  },

  '& svg': {
    transition: '0.2s ease-in-out transform',
  },

  ':hover': {
    '&:before': {
      opacity: 0.9,
    },
    '& svg': {
      transform: 'scale(1.2)',
    },
  },

  extend: [
    maxWidth(BREAKPOINTS.sm, {
      width: BASE_MARGIN.small,
    }),
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      width: BASE_MARGIN.medium,
    }),
    minWidth(BREAKPOINTS.md, {
      width: BASE_MARGIN.large,
    }),
    {
      condition: position === 'left',
      style: {
        left: 0,
        ':before': {
          background: `linear-gradient(90deg, ${theme.color.carouselArrowBackground}, transparent)`,
        },
      },
    },
    {
      condition: position === 'right',
      style: {
        right: 0,
        ':before': {
          background: `linear-gradient(270deg, ${theme.color.carouselArrowBackground}, transparent)`,
        },
      },
    },
    {
      condition: !isVisible,
      style: {
        display: 'none',
      },
    },
  ],
}), 'div', ['position', 'isVisible']);

export const Arrow = createComponent(({ theme, top, height }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: top,
  height: height ? `${height}px` : '100%',
  color: theme.color.carouselArrow,
  zIndex: '10',
  filter: `drop-shadow(${theme.textShadow.carouselArrow})`,
  transform: 'translateZ(0)', // handle safari
}), 'div', ['top', 'height']);

export const ScrollableContainer = createComponent(() => ({
  position: 'relative',

  '&:hover .arrow': {
    visibility: 'visible',
  },
}), 'div');

export const ItemContainer = createComponent(({ marginLeft, marginRight }) => ({
  marginLeft,
  marginRight,
  '&:nth-child(-n+2)': {
    // do not apply margin left to first 2 element as
    // 1st element is a spacer and the 2nd element is the first item
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: `calc(${marginLeft} + ${marginRight})`,
  },
}), ItemWrapper, ['marginLeft', 'marginRight']);

ItemContainer.displayName = 'ItemContainer';
