import React from 'react';
import { useDispatch } from 'reducers';
import { useRouter } from 'router';
import logger from 'utils/logger';
import { useI18n } from 'components/I18n';
import { stripePurchase } from 'actions/billing';
import {
  AsyncButton,
  Text,
} from 'components';
import { ConflictError, DownstreamError } from 'utils/errors';

interface IPurchaseButtonProps {
  id: string;
  originalOfferId?: string;
  promoCode?: string;
  bypassCheckout?: boolean;
  createAccount?: boolean;
  useExistingCard?: boolean;
  redirectTo?: string;
}

function PurchaseButton(props: IPurchaseButtonProps) {
  const {
    id,
    originalOfferId,
    promoCode,
    bypassCheckout,
    createAccount,
    useExistingCard = false,
    redirectTo,
  } = props;
  const i18n = useI18n();
  const router = useRouter();
  const dispatch = useDispatch();

  const startSubscription = async () => {
    const returnLocation = {
      name: 'checkout-receipt',
      query: {
        createAccount,
        ...(promoCode ? { promoCode } : null),
        ...(redirectTo ? { redirectTo } : null),
        packageId: id,
        bypassCheckout: bypassCheckout ? null : undefined,
      },
    };

    try {
      const returnUrl = router.getUrl(returnLocation, false);

      await dispatch(
        stripePurchase(id, promoCode, returnUrl, useExistingCard, originalOfferId),
      );
    } catch (e) {
      logger.error(`Purchase of ${id} ${promoCode || ''} failed`, e);

      if (
        e instanceof ConflictError
        && e.message
        && i18n.hasMessage(`payment.downstreamError.${e.message}`)
      ) {
        throw i18n.formatText(`payment.downstreamError.${e.message}`);
      } else if (
        e instanceof DownstreamError
        && e.serviceMessage
        && i18n.hasMessage(`payment.downstreamError.${e.serviceMessage}`)
      ) {
        throw i18n.formatText(`payment.downstreamError.${e.serviceMessage}`);
      }

      throw i18n.formatText('error');
    }
  };

  return (
    <AsyncButton type="button" variant="brand" fixedWidth onClick={startSubscription} infinite>
      <Text
        id={bypassCheckout ? 'payment.bypassCheckoutBtn' : 'payment.goToPayment'}
      />
    </AsyncButton>
  );
}

export default React.memo(PurchaseButton);
