import createComponent from 'styles/fela/createComponent';
import { smallScreen } from 'styles/fela/mixins';
import { CHANNEL_SIZE } from '../Timeline/ChannelList';
import { TIMEBAR_HEIGHT } from '../Timebar/Timebar';
import { EPG_HEADER_HEIGHT } from '../EpgStyles';

const width = 55;

// needed to not cut the shadow because of `overflow: hidden`.
const padding = 5;

export const ScrollButtonWrapper = createComponent(({ theme, isLeft }) => ({
  width: `${(width + (padding * 2))}px`,
  overflow: 'hidden',
  userSelect: 'none',
  cursor: 'pointer',

  position: 'absolute',
  top: EPG_HEADER_HEIGHT + TIMEBAR_HEIGHT,
  bottom: TIMEBAR_HEIGHT,
  zIndex: 4,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.color.carouselArrow,

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    transition: '0.2s ease-in-out opacity',
    opacity: 0.3,
  },

  '& svg': {
    transition: '0.2s ease-in-out transform',
    filter: `drop-shadow(${theme.textShadow.carouselArrow})`,
    transform: 'translateZ(0)', // handle safari
  },

  ':hover': {
    '&:before': {
      opacity: 0.5,
    },
    '& svg': {
      transform: 'scale(1.2)',
    },
  },

  extend: [
    smallScreen({
      display: 'none',
    }),
    {
      condition: isLeft,
      style: {
        left: CHANNEL_SIZE.width,
        '&:before': {
          background: `linear-gradient(90deg, ${theme.color.epgArrowBackground}, transparent)`,
        },
      },
    },
    {
      condition: !isLeft,
      style: {
        right: 0,
        '&:before': {
          background: `linear-gradient(270deg, ${theme.color.epgArrowBackground}, transparent)`,
        },
      },
    },
  ],
}), 'div', ['isLeft']);

export const Arrow = createComponent(({ theme }) => ({
  color: theme.color.carouselArrow,
}), 'div', []);
