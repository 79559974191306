import createComponent from 'styles/fela/createComponent';
import { Box } from 'components';
import { withBasePadding } from 'styles/fela/mixins';

export const CollectionContainer = createComponent(({ theme }) => ({
  extend: [
    {
      condition: theme.collectionUI.withTextShadow,
      style: {
        filter: `drop-shadow(${theme.textShadow.featured})`,
        transform: 'translateZ(0)', // handle safari
      },
    },
  ],
}), Box);

export const Content = createComponent(() => ({
  extend: [
    withBasePadding(),
  ],
}), Box);

export const ActionButtonsContainer = createComponent(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginTop: theme.margin.medium,
  minHeight: '3.5rem',
}), 'div');
