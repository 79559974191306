import React from 'react';
import createComponent from 'styles/fela/createComponent';

const StyledButton = createComponent(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: 'inherit',
  display: 'flex',
  height: '3rem',
  justifyContent: 'center',
  width: '3rem',
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  padding: '0 1rem',
}), 'button', []);

type IconButtonProps = React.HTMLAttributes<HTMLElement> & {
  children: React.ReactNode;
  onClick: () => void;
};

const IconButton: React.FC<IconButtonProps> = ({ children, ...props }) => {

  return (
    <StyledButton type="button" {...props}>
      {children}
    </StyledButton>
  );
};

export default IconButton;
