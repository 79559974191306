import React, { useEffect, useRef, useState } from 'react';
import { useFela } from 'react-fela';
import { ComponentRuleProps } from 'styles/fela/createComponent';
import { CSSObject, IStyleExtension } from 'fela';
import {
  responsiveSm,
  responsiveMd,
  withOverlay,
} from 'styles/fela/mixins';
import { GlobalTheme, LayoutObjectType } from 'types';
import { hashCode } from 'utils/helpers';
import { useAllCustomFontsLoaded } from 'utils/hooks';

interface CustomRuleStyles extends CSSObject{
  extend?: Array<{
    condition: any,
    style: CSSObject | IStyleExtension | { [name: string]: CSSObject },
  }> | Array<CSSObject>;
}

const metadataContainer = (
  {
    theme,
    overlay,
    withLiveLabel,
    showBlur,
    visible,
  }: ComponentRuleProps,
): CustomRuleStyles => ({
  position: 'relative',
  zIndex: 1,
  padding: '2rem 0',
  marginBottom: '2rem',
  maxHeight: `calc(100% - 1rem - ${theme.header.height})`,
  display: 'flex',
  flexDirection: 'column',
  transition: 'opacity 0.3s',
  opacity: visible ? 1 : 0,
  width: '100%',

  extend: [
    responsiveSm({
      width: '32rem',
    }),
    responsiveMd({
      width: '47vw',
    }),
    {
      condition: withLiveLabel,
      style: {
        marginTop: '2rem',
      },
    },
    {
      condition: overlay,
      style: {
        ...withOverlay(overlay) as CSSObject,
        padding: '2rem 1.5rem',
      },
    },
    {
      condition: overlay && showBlur,
      style: {
        '@supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px)))': {
          backdropFilter: 'blur(5px)',
        },
      },
    },
  ],
});

type MetadataContainerProps = {
  children: React.ReactNode,
  layoutObjects: LayoutObjectType[],
  viewableId: string,
  showBlur: boolean,
  overlay: string,
  withLiveLabel?: boolean,
};

const MetadataContainer = (
  { children, layoutObjects, viewableId, ...restProps }: MetadataContainerProps,
): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const allFontsLoaded = useAllCustomFontsLoaded(layoutObjects);
  const { css } = useFela<GlobalTheme>({
    visible: allFontsLoaded,
    ...restProps,
  });
  const componentKey = `featured-${viewableId}-${hashCode(JSON.stringify(layoutObjects) ?? '')}`;
  const [metadataKey, setMetadataKey] = useState('');

  useEffect(() => {
    if (ref.current?.dataset.key !== componentKey) {
      setMetadataKey(componentKey);
    }
  }, [ref.current]);

  return (
    <div
      suppressHydrationWarning
      key={__SERVER__ ? componentKey : metadataKey}
      data-key={metadataKey}
      ref={ref}
      className={css(metadataContainer)}
    >
      {children}
    </div>
  );
};

export default MetadataContainer;
