import logger from 'utils/logger';
import { VIEWABLE_TYPES } from 'utils/constants';
import { ViewableType } from 'types';

/**
 * Determine the title and image to display based on the type of the
 * viewable. Chanels will display the title and image for the currently
 * live broadcast.
 *
 * @param {Object} viewable The featured viewable
 */
export const getTitleAndImage = (
  viewable: ViewableType,
): {
  image: string | undefined;
  title: string,
} => {
  if (viewable.__typename === VIEWABLE_TYPES.Channel) {
    if (!viewable.schedule) {
      logger.warn(`Featured channel ${viewable.title}(${viewable.id}) does not have a schedule. Retrieving channel title and image.`);

      return {
        title: viewable.title,
        image: viewable.banner,
      };
    }

    if (viewable.schedule.length > 0) {
      const broadcast = viewable.schedule[0];
      return {
        title: broadcast.title,
        image: broadcast.banner,
      };
    }

    logger.info(`Featured channel ${viewable.title} (${viewable.id}) does not have a schedule. Using channel title and image.`);
  }

  return {
    title: viewable.title,
    image: viewable.banner,
  };
};

export function getProperViewable(viewable: ViewableType): ViewableType {
  // Show's offers buttons is always for E1:S1
  return viewable?.seasons?.[0]?.episodes?.[0] || viewable;
}
