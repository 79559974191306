import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ieInputHideClearIcon } from 'styles/fela/mixins';
import createComponent from 'styles/fela/createComponent';

const Container = createComponent(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}), 'form');

const PinInput = createComponent(({ theme, isFilledIn }) => ({
  flexShrink: '1',
  borderRadius: '3px',
  border: '0',
  fontSize: '2rem',
  textAlign: 'center',
  color: theme.color.pinInputText,
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  background: theme.color.pinInputUnselectedOverlay,
  outline: 'none',
  ':focus': {
    background: theme.color.inputBackground,
  },
  ':hover': {
    background: theme.color.inputBackground,
  },
  extend: [
    ieInputHideClearIcon(),
    {
      condition: isFilledIn,
      style: {
        background: theme.color.inputBackground,
      },
    },
  ],
}), 'input', ['isFilledIn']);

const PinContainer = createComponent(({ theme }) => ({
  position: 'relative',
  height: '56px',
  width: '42px',
  marginLeft: theme.margin.pinInputs,
  borderRadius: '3px',
  background: theme.color.pinInputBackground,

  ':first-child': {
    marginLeft: 0,
  },
}));

const isDigit = string => /^\d$/.test(string);

const ACTION = {
  PREVIOUS: 'PREVIOUS',
  NEXT: 'NEXT',
};

const PinInputs = React.forwardRef((props, ref) => {
  const {
    onChangePinReadyForSubmit = () => {},
    onPinEntered,
  } = props;

  const inputRefs = useRef([]);
  const [value, setValue] = useState(['', '', '', '']);
  const action = useRef();

  const focusInput = (index) => {
    inputRefs.current[index].focus();
  };

  const updateValue = (index, char) => {
    const newValue = [...value];
    newValue[index] = char;
    setValue(newValue);
  };

  const isPinCodeComplete = () => {
    const pinCode = value.join('');

    return pinCode.length === value.length;
  };

  useEffect(() => {
    if (action.current) {
      const { type, index } = action.current;
      focusInput(index);
      if (type === ACTION.PREVIOUS) {
        onChangePinReadyForSubmit(false);
      } else if (action.current?.type === ACTION.NEXT) {
        onChangePinReadyForSubmit(isPinCodeComplete());
      }
      action.current = null;
    }
  }, [value]);

  const submit = () => {
    const pinCode = value.join('');

    if (isPinCodeComplete()) {
      onPinEntered(pinCode);
      setValue(['', '', '', '']);
      focusInput(0);
      onChangePinReadyForSubmit(false);
    }
  };

  useImperativeHandle(ref, () => ({
    submit() {
      submit();
    },
  }), [value]);

  const onKeyDown = (e, index) => {
    const prev = Math.max(0, index - 1);
    const next = Math.min(value.length - 1, index + 1);

    // prevent player scrolling
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation();
    }

    if (e.key === 'Backspace') {
      updateValue(index, '');
      action.current = { type: ACTION.PREVIOUS, index: prev };
    } else if (e.key === 'ArrowLeft') {
      focusInput(prev);
    } else if (e.key === 'ArrowRight') {
      focusInput(next);
    } else if (e.key === 'Enter') {
      submit();
    }
  };

  const onChange = (e, index) => {
    const next = Math.min(value.length - 1, index + 1);

    const { value: elementValue } = e.target;
    if (isDigit(elementValue)) {
      updateValue(index, elementValue);
      action.current = { type: ACTION.NEXT, index: next };
    }
  };

  const isInputFilledIn = index => !!value[index];

  const renderInput = (index, autoFocus = false) => (
    <PinContainer
      key={index}
    >
      <PinInput
        key={index}
        autoFocus={autoFocus}
        innerRef={(element) => { inputRefs.current[index] = element; }}
        onKeyDown={e => onKeyDown(e, index)}
        onChange={e => onChange(e, index)}
        type="password"
        value={value[index]}
        isFilledIn={isInputFilledIn(index)}
      />
    </PinContainer>
  );

  return (
    <Container>
      {value.map((_, index) => renderInput(index, index === 0))}
    </Container>
  );
});

PinInputs.propTypes = {
  /**
   * This function takes 1 boolean argument determining if the pin is
   * in fact complete. Will be dispatched both when it becomes incomplete
   * and when it becomes complete.
   */
  onChangePinReadyForSubmit: PropTypes.func,
  onPinEntered: PropTypes.func.isRequired,
};

export default PinInputs;
