import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  AdyenResultHandler,
} from 'components/Payment';
import {
  Box,
  ErrorMessage,
  Heading,
  Text,
  Spinner,
  PanelContent,
} from 'components';
import AdyenDropInComponents from 'components/AdyenDropIn/AdyenDropInComponents';
import GET_ADYEN_DROPIN_PAYMENT_METHODS from 'components/AdyenDropIn/getAdyenDropInPaymentMethods.gql';
import { useRouter } from 'router';

function AdyenPayment({
  createAccount,
  packageId,
  promoCode,
  redirectTo,
  offer,
  bypassPaymentMethod,
  originalOfferId,
}) {
  const {
    data: paymentsData,
    loading,
    error: apolloError,
  } = useQuery(GET_ADYEN_DROPIN_PAYMENT_METHODS, {
    variables: {
      offerId: packageId,
      originalOfferId,
      promoCode,
    },
  });

  const [adyenError, setAdyenError] = React.useState(null);
  const router = useRouter();

  if (loading) {
    return <Box my="xxxlarge"><Spinner size={3} strokeWidth={2} /></Box>;
  }

  if (apolloError) {
    return (
      <PanelContent centered width="100%">
        <ErrorMessage id="error" />
      </PanelContent>
    );
  }

  if (loading && !paymentsData) {
    return null;
  }

  const returnLocation = {
    name: 'checkout-receipt',
    query: {
      createAccount,
      ...(promoCode ? { promoCode } : null),
      ...(redirectTo ? { redirectTo } : null),
      packageId,
      ...(originalOfferId ? { originalOfferId } : null),
      bypassCheckout: bypassPaymentMethod ? null : undefined,
    },
  };

  const returnPath = router.getUrl(returnLocation, false);
  const finalPrice = paymentsData?.viewer.result.priceInCents;
  const { currency, __typename: offerType } = offer;

  if (promoCode && bypassPaymentMethod) {
    return (
      <Box fullWidth column>
        <Box row my="xxxlarge">
          <AdyenDropInComponents
            packageId={packageId}
            promoCode={promoCode}
            price={finalPrice}
            currency={currency}
            returnPath={returnPath}
            bypassPaymentMethod={bypassPaymentMethod}
            offerType={offerType}
            onError={setAdyenError}
          />
          <Spinner size={7} strokeWidth={2} />
        </Box>
        <Text
          id="payment.transactionInProgress"
          display="block"
          fontSize="medium"
          align="center"
        />
      </Box>
    );
  }

  return (
    <PanelContent centered width="100%">
      <Box mb="medium">
        <AdyenResultHandler />
      </Box>

      <Box mb="medium">
        {adyenError && (
          <ErrorMessage id="error" />
        )}
      </Box>

      <Box
        justifyContent="space-around"
        width="100%"
        maxWidth="45rem"
      >
        <Heading
          id="paymentView.paymentMethodTitle"
          fontSize="large"
          align="center"
          mb="large"
        />

        <AdyenDropInComponents
          packageId={packageId}
          promoCode={promoCode}
          price={finalPrice}
          currency={currency}
          returnPath={returnPath}
          bypassPaymentMethod={bypassPaymentMethod}
          onError={setAdyenError}
          originalOfferId={originalOfferId}
          offerType={offerType}
        />
      </Box>
    </PanelContent>
  );
}

AdyenPayment.propTypes = {
  packageId: PropTypes.string.isRequired,
  promoCode: PropTypes.string,
  redirectTo: PropTypes.string,
  originalOfferId: PropTypes.string,
  offer: PropTypes.object,
  bypassPaymentMethod: PropTypes.bool,
  createAccount: PropTypes.bool,
};

export default React.memo(AdyenPayment);
