import { ApolloClient } from '@apollo/client';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import logger from 'utils/logger';
import queryString from 'query-string';
import { ADYEN_PATHS, ADYEN_RESULT_TYPES, OFFER_TYPES, OfferType } from 'utils/constants';
import { getBaseFontFamily } from 'utils/helpers';
import { I18n } from 'components/I18n/types';
import { GlobalTheme, PaymentMethods } from 'types';
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import GET_ADYEN_DROPIN_PAYMENT_METHODS from './getAdyenDropInPaymentMethods.gql';
import MAKE_ADYEN_DROPIN_PAYMENT from './makeAdyenDropInPayment.gql';
import SAVE_ADYEN_CHECKOUT_PAYMENT_METHOD from './saveAdyenCheckoutPaymentMethod.gql';
import ADYEN_PAYMENT_DETAILS from './submitAdyenCheckoutPaymentDetails.gql';

async function getAdyenCheckout() {
  const [
    AdyenCheckoutModule,
  ] = await Promise.all([
    import('@adyen/adyen-web'),
    import('@adyen/adyen-web/dist/adyen.css'),
  ]);

  return AdyenCheckoutModule.default;
}

interface IConfig {
  packageId: string
  originalOfferId?: string
  promoCode?: string
  price: number,
  currency: string,
  returnUrl: string,
  transactionId: string,
  onError: (err: Error) => void,
  flowType: FlowTypes,
  locale: DropInLocales,
  i18n: I18n,
  theme: GlobalTheme,
  offerType: OfferType,
}

export enum FlowTypes {
  SETUP,
  PAYMENT,
}

// https://docs.adyen.com/online-payments/web-drop-in/customization#language-and-localization
export enum DropInLocales {
  en = 'en-US',
  el = 'el-GR',
  sv = 'sv-SE',
  es = 'es-ES',
  de = 'de-DE',
  it = 'it-IT',
  da = 'da-DK',
}

const shouldHideCvcAndExpDate = (paymentMethod: PaymentMethod, flowType: FlowTypes, offerType: OfferType) => (
  flowType !== FlowTypes.SETUP
  && paymentMethod && paymentMethod.type !== PaymentMethods.PAYPAL
  && (
    paymentMethod.brand?.endsWith('_googlepay')
    || paymentMethod.brand?.endsWith('_applepay')
    || offerType === OFFER_TYPES.SubscribeType
  )
);
const clearExpDate = (paymentMethod: any) => {
  delete paymentMethod.expiryMonth;
  delete paymentMethod.expiryYear;
};

export class AdyenDropInManager {
  private _inProgress = false;

  constructor(
    private _client: ApolloClient<unknown>,
    private _config: IConfig,
  ) {
  }

  private async _getPaymentMethods(): Promise<IPaymentMethods> {
    try {
      const result = await this._client.query({
        query: GET_ADYEN_DROPIN_PAYMENT_METHODS,
        variables: {
          offerId: this._config.packageId,
          originalOfferId: this._config.originalOfferId,
          promoCode: this._config.promoCode,
        },
      });

      return result.data.viewer.result;
    } catch (e) {
      logger.error('Failed to get adyen dropin payment methods', e);

      throw e;
    }
  }

  private async _makePayment(dropInEventData: unknown): Promise<any> {
    try {
      const result = await this._client.mutate({
        mutation: MAKE_ADYEN_DROPIN_PAYMENT,
        variables: {
          offerId: this._config.packageId,
          originalOfferId: this._config.originalOfferId,
          promoCode: this._config.promoCode,
          returnUrl: this._config.returnUrl,
          currency: this._config.currency,
          netAmountCents: this._config.price,
          dropInEventData: JSON.stringify(dropInEventData),
        },
      });

      return {
        adyenResponse: JSON.parse(result.data.result.adyenResponse),
        transactionId: result.data.result.transactionId,
      };
    } catch (e) {
      logger.error('Failed to make adyen dropin payment', e);

      throw e;
    }
  }

  private async _savePayment(dropInEventData: any): Promise<any> {
    try {
      const result = await this._client.mutate({
        mutation: SAVE_ADYEN_CHECKOUT_PAYMENT_METHOD,
        variables: {
          returnUrl: this._config.returnUrl,
          currency: 'USD',
          dropInEventData: JSON.stringify(dropInEventData),
        },
      });

      return {
        adyenResponse: JSON.parse(result.data.result.adyenResponse),
        transactionId: result.data.result.transactionId,
      };
    } catch (e) {
      logger.error('Failed to make adyen dropin payment', e);

      throw e;
    }
  }

  private async _submitPaymentDetails(dropInEventData: any): Promise<any> {
    try {
      const result = await this._client.mutate({
        mutation: ADYEN_PAYMENT_DETAILS,
        variables: {
          input: {
            transactionId: this._config.transactionId,
            details: JSON.stringify(dropInEventData),
          },
        },
      });

      return {
        adyenResponse: JSON.parse(result.data.result.adyenResponse),
      };
    } catch (e) {
      logger.error('Failed to submit payment details', e);

      throw e;
    }
  }

  private _openFinalPage() {
    window.location.href = this._config.returnUrl;
  }

  private static _resetPayment(dropIn: UIElement, timeout: undefined | number = undefined) {
    setTimeout(() => dropIn.setStatus('ready'), timeout ?? 5000);
  }

  async bypassPaymentMethod(): Promise<void> {
    await this._makePayment(null);
    this._openFinalPage();
  }

  async renderComponent(domEl: HTMLElement): Promise<void> {
    const AdyenCheckout = await getAdyenCheckout();
    const {
      clientKey,
      paymentMethodsResponse,
    } = await this._getPaymentMethods();

    let translations = {};
    let price = this._config.price ?? 0;

    // https://magine.atlassian.net/browse/MDM-17778
    if (this._config.i18n.hasMessage('adyen.confirmPreauthorization')) {
      price = 0; // when price == 0 adyen use confirmPreauthorization translation
      translations = {
        [this._config.locale]: {
          confirmPreauthorization: this._config.i18n.formatText('adyen.confirmPreauthorization'),
        },
      };
    }

    // https://magine.atlassian.net/browse/MDM-17779
    const paymentMethodsData = JSON.parse(paymentMethodsResponse);
    if (this._config.i18n.hasMessage('adyen.creditCard')) {
      const method = paymentMethodsData?.paymentMethods.find((m: any) => m?.brands?.includes('visa'));
      if (method) {
        method.name = this._config.i18n.formatText('adyen.creditCard');
      }
    }

    // Hide Exp date and CVC inputs for Google/Apple pay
    const hideCvcAndExpDate = shouldHideCvcAndExpDate(
      paymentMethodsData.storedPaymentMethods?.[0],
      this._config.flowType,
      this._config.offerType,
    );

    if (hideCvcAndExpDate) {
      // remove expiration date to hide its input
      clearExpDate(paymentMethodsData.storedPaymentMethods[0]);
      domEl.classList.add('third-party-stored-payment');
    }

    const checkout = await AdyenCheckout({
      paymentMethodsResponse: paymentMethodsData,
      clientKey,
      environment: clientKey.startsWith('test_') ? 'test' : 'live',
      locale: this._config.locale,
      translations,
      // for the "Pay" button
      amount: (this._config.flowType === FlowTypes.SETUP && !price && !this._config.currency)
        ? { value: 0, currency: 'USD' }
        : { value: price, currency: this._config.currency },
      paymentMethodsConfiguration: {
        storedCard: {
          hideCVC: hideCvcAndExpDate,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: false,
          styles: {
            error: {
              color: this._config.theme.color.adyenDropInInputColor,
            },
            placeholder: {
              color: this._config.theme.color.adyenDropInInputPlaceholder,
            },
            base: {
              color: this._config.theme.color.adyenDropInInputColor,
              fontFamily: getBaseFontFamily(this._config.theme),
              webkitFontSmoothing: 'antialiased',
            },
          },
        },
      },
      onAdditionalDetails: (state, dropIn) => {
        void (async ()=> {
          const { adyenResponse } = await this._submitPaymentDetails(state.data.details);

          if (!dropIn) return;

          if (adyenResponse.action) { // additional payment details needed
            dropIn.handleAction(adyenResponse.action);
            return;
          }
          switch (adyenResponse.resultCode.toUpperCase()) {
            case ADYEN_RESULT_TYPES.AUTHORISED: {
              dropIn.setStatus('success');// everything is fine, redirect to the final page
              this._openFinalPage();
              break;
            }
            default: {
              dropIn.setStatus('error');
              AdyenDropInManager._resetPayment(dropIn);
            }
          }
        })();
      },
      onSubmit: (state, dropIn) => {
        void (async () => {
          if (!state.isValid || this._inProgress) {
            return;
          }

          this._inProgress = true;

          try {
            const { adyenResponse, transactionId } = this._config.flowType === FlowTypes.PAYMENT
              ? await this._makePayment(state.data)
              : await this._savePayment(state.data);

            this._config.transactionId = transactionId;

            if (adyenResponse.action?.type === 'redirect') {
              const params = queryString.stringify({
                redirect: this._config.returnUrl.replace('https://', ''),
                transactionId,
              });
              adyenResponse.action.data.TermUrl = `${window.location.origin}${ADYEN_PATHS.DROPIN_REDIRECT_PATH}?${params}`;
            }

            if (adyenResponse.action) { // additional payment details needed
              dropIn.handleAction(adyenResponse.action);
              return;
            }

            switch (adyenResponse.resultCode.toUpperCase()) {
              case ADYEN_RESULT_TYPES.AUTHORISED: {
                dropIn.setStatus('success');// everything is fine, redirect to the final page
                this._openFinalPage();
                break;
              }
              case ADYEN_RESULT_TYPES.REFUSED:
              case ADYEN_RESULT_TYPES.CANCELLED:
              case ADYEN_RESULT_TYPES.ERROR: {
                const reasonI18nKey = `payment.refusal_${adyenResponse.refusalReason}`;
                dropIn.setStatus('error', {
                  message: this._config.i18n.hasMessage(reasonI18nKey)
                    ? this._config.i18n.formatText(reasonI18nKey)
                    : adyenResponse.refusalReason,
                });
                AdyenDropInManager._resetPayment(dropIn);
                break;
              }
            }
          } catch (e: any) {
            this._config.onError(e);
            AdyenDropInManager._resetPayment(dropIn, 0);
          } finally {
            this._inProgress = false;
          }
        })();
      },
      onError: (error) => {
        logger.error('Adyen dropin error', error);
      },
    });

    checkout.create('dropin', { showStoredPaymentMethods: this._config.flowType !== FlowTypes.SETUP }).mount(domEl);
  }
}

interface IPaymentMethods {
  clientKey: string
  paymentMethodsResponse: string
}
