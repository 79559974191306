import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ENTITLEMENT_TYPES } from 'utils/constants';
import Text from 'components/StyledSystem/Text';
import MetaCountdown from './MetaCountdown';

export const SUPPORTED_ENTITLEMENT_TYPES = [
  ENTITLEMENT_TYPES.RENT,
  ENTITLEMENT_TYPES.PASS,
];

export const tvodTimer = (timer) => {
  if (!timer) return null;

  const { days, hours, minutes, seconds } = timer;

  if (!days && !hours && !minutes) {
    return (
      <Text id="date.seconds" values={{ seconds }} className="dateText" />
    );
  }

  // no minutes if we still have more than 1 day
  const values = { days, hours, minutes: days ? 0 : minutes };

  return (
    <Text id="date.daysHoursMinutes" values={values} className="dateText" />
  );
};

const REFETCH_TIMEOUT = 30000;

const TVODCountdown = ({ entitlement, refreshData }) => {
  const timeout = useRef(null);
  useEffect(() => () => clearTimeout(timeout.current), []);

  if (!SUPPORTED_ENTITLEMENT_TYPES.includes(entitlement?.__typename)) {
    return null;
  }

  const entitledUntil = (entitlement.endDate || new Date(entitlement.entitledUntil)).getTime();

  // user can have wrong time or server delay
  // we will still get entitlement from the server after timer became 0
  // in this case we will refetch viewable after delay
  if (entitledUntil < Date.now() && !timeout.current && refreshData) {
    timeout.current = setTimeout(() => {
      timeout.current = null;
      refreshData();
    }, REFETCH_TIMEOUT);
  }

  if (timeout.current) {
    return null;
  }

  const time = (
    <MetaCountdown
      key={entitledUntil}
      eventTimeMs={entitledUntil}
      onEvent={refreshData}
    >
      {tvodTimer}
    </MetaCountdown>
  );

  return (
    <Text id="timeLeft" values={{ time }} />
  );
};

TVODCountdown.propTypes = {
  entitlement: PropTypes.shape({
    __typename: PropTypes.oneOf(Object.values(ENTITLEMENT_TYPES)).isRequired,
    entitledUntil: PropTypes.string, // ISO-8601
    endDate: PropTypes.instanceOf(Date),
  }),
  refreshData: PropTypes.func,
};

export default TVODCountdown;
