import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { smallScreen, withBasePadding } from 'styles/fela/mixins';
import { IconButton, CloseIcon, CircleCheckIcon } from 'components/Icons';
import { BASE_MARGIN } from 'utils/constants';

const Container = createComponent(({ theme, bottom, fullWidth, type }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.modal,
  top: '2px',
  left: '50%',
  transform: 'translateX(-50%)',

  color: theme.color.snackbarColor,
  backgroundColor: theme.color.snackbarBackground,
  padding: `${theme.margin.large} ${theme.margin.xxxlarge} ${theme.margin.large} ${theme.margin.xlarge}`,
  borderRadius: theme.radius.default,
  boxShadow: `0 1px 3px 0 ${theme.color.overlayDarkFaint}, 0 1px 1px 0 ${theme.color.overlayDarkTranslucent}, 0 2px 1px -1px ${theme.color.overlayDarkTranslucent}`,

  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',

  extend: [
    smallScreen({
      width: '100%',
      borderRadius: 0,
      paddingLeft: BASE_MARGIN.small,
      paddingRight: BASE_MARGIN.small,
    }),
    {
      condition: bottom,
      style: {
        top: 'auto',
        bottom: '0',
      },
    },
    {
      condition: fullWidth,
      style: {
        lef: '0',
        width: '100%',
        borderRadius: '0',
        extend: [
          withBasePadding(),
        ],
      },
    },
    {
      condition: type === 'success',
      style: {
        backgroundColor: theme.color.successNotification,
        fontWeight: 'bold',
      },
    },
  ],
}), 'div', ['bottom', 'fullWidth', 'type']);

const CloseButtonContainer = createComponent(({ center }) => ({
  position: 'absolute',
  right: '9px',
  top: '0',

  extend: [
    smallScreen({
      right: '0',
    }),
    {
      condition: center,
      style: {
        bottom: '0',
        right: '0',
        display: 'flex',
        alignItems: 'center',
      },
    },
  ],
}), 'div', ['center']);

const StyledIconButton = createComponent(({ center, fullWidth }) => ({
  width: 'auto !important',
  extend: [
    smallScreen({
      extend: [
        {
          condition: center,
          style: {
            padding: '0.25rem !important',
          },
        },
      ],
    }),
    {
      condition: fullWidth,
      style: {
        height: 'auto !important',
        padding: '0.25rem !important',
      },
    },
  ],
}), IconButton, ['center', 'fullWidth']);

const Notification = (props) => {
  const {
    bottom,
    fullWidth,
    children,
    onClose,
    type,
  } = props;

  return (
    <Container bottom={bottom} fullWidth={fullWidth} type={type}>
      {type === 'success' && (<CircleCheckIcon size="1.3rem" style={{ marginRight: '0.5rem' }} />)}

      {children}

      <CloseButtonContainer center={!bottom}>
        <StyledIconButton onClick={onClose} center={!bottom} fullWidth={fullWidth} >
          <CloseIcon />
        </StyledIconButton>
      </CloseButtonContainer>
    </Container>
  );
};

Notification.propTypes = {
  onClose: PropTypes.func.isRequired,
  bottom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

export default Notification;
