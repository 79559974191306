import React, { useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import * as ApiErrors from '@tvoli/js-api-client/src/errors';
import * as authActions from 'actions/auth';
import * as userActions from 'actions/user';
import * as offersActions from 'actions/offers';
import { PLATFORMS, PLATFORMS_NAME } from 'utils/constants';
import logger from 'utils/logger';
import {
  PrimaryButton,
  GeneralError,
  Box,
  Heading,
  Text,
  Panel,
  PanelContent,
  PanelWrapper,
  Metadata,
} from 'components';
import { I18nContext } from 'components/I18n';
import { useAnalytics } from 'components/Tracking';
import {
  Checkbox,
  FormField,
  InputFields,
} from 'components/Forms';
import {
  getDefaultFormStateFromQuery,
} from 'utils/forms';
import { useRecaptchaToken } from 'components/Recaptcha/RecaptchaTracker';
import RecaptchaDisclaimer from 'components/Recaptcha/RecaptchaDisclaimer';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import TermsAndConditionsLink from './TermsAndConditionsLink';
import ErrorScreenOverlay from './ErrorScreenOverlay';
import { REDIRECT_TO_PAGES } from './constants';

const parseRedirectTo = (redirectToStr) => {
  if (!redirectToStr) return {};

  try {
    return JSON.parse(redirectToStr);
  } catch (e) {
    logger.warn('Failed to parse redirectTo', e);
  }

  return {};
};

const shouldBeRedirectedToRoute = ({ name }) => REDIRECT_TO_PAGES.includes(name);

const getNextQuery = (redirectTo) => {
  if (redirectTo.name === 'checkout') {
    return {
      createAccount: null,
      redirectTo: undefined,
      ...(redirectTo.query ?? {}),
    };
  }

  return {
    createAccount: null,
    redirectTo: JSON.stringify(redirectTo),
  };
};

function CreateAccountView() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const i18n = useContext(I18nContext);

  const {
    locale,
    geoipCountry,
    redirectTo,
    purchase,
    isPurchaseFlowEnabled,
    isContentPagePaymentEnabled,
    showNewsletterCheckbox,
    emailOptIn,
    query,
    registerMethods,
    devicePlatform,
    deviceModel,
    browserName,
    browserModel,
    deviceType,
    qrCode,
    brandName,
  } = useSelector((state) => {
    const devicePlatformState = state.router.location.params?.platform;
    const isPurchaseFlowEnabledState = state.country.isPurchaseFlowEnabled
      && ![PLATFORMS.ANDROID, PLATFORMS.IOS, PLATFORMS.MOVISTAR].includes(devicePlatformState);

    let deviceTypeState = 'web';

    if (devicePlatformState) {
      if (devicePlatformState === PLATFORMS.MOVISTAR) {
        deviceTypeState = 'stb';
      } else {
        deviceTypeState = 'smarttv';
      }
    }

    return ({
      locale: state.settings.l10n.language,
      geoipCountry: state.country.geoipCountry,
      redirectTo: parseRedirectTo(state.router.query?.redirectTo),
      purchase: state.router.query?.purchase,
      isPurchaseFlowEnabled: isPurchaseFlowEnabledState,
      isContentPagePaymentEnabled: !!state.settings.features.payment?.content,
      showNewsletterCheckbox: state.settings.features?.newsletter?.showCheckbox || false,
      emailOptIn: state.settings.features?.newsletter?.isDefaultOptIn || false,
      query: state.router.query,
      registerMethods: state.settings.features.registerMethods,
      devicePlatform: devicePlatformState,
      deviceModel: state.router.query?.model,
      browserName: (state.common.browser ?? '').toLowerCase(),
      browserModel: (state.common.description ?? '').toLowerCase(),
      deviceType: state.router.query?.type ?? deviceTypeState,
      qrCode: state.router.query?.qrCode,
      brandName: state.settings.brand.name,
    });
  }, shallowEqual);

  const signupInfo = {
    devicePlatform: devicePlatform ?? browserName,
    deviceModel: devicePlatform ? deviceModel : browserModel,
    deviceType,
    qrCode,
    url: typeof window !== 'undefined' ? window.location?.href : undefined,
    proxyDeviceType: devicePlatform ? 'web' : undefined,
  };

  // useMemo prevents from blinking as redirectTo disappear first
  const isRedirectToEnabled = useMemo(
    () => shouldBeRedirectedToRoute(redirectTo),
    [],
  );

  const [inProgress, setInProgress] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [isAccountInUse, setIsAccountInUse] = useState({ status: false, username: null });

  const formStateFromQuery = useMemo(
    () => getDefaultFormStateFromQuery(registerMethods.inputGroups, query),
    [registerMethods.inputGroups, query],
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      emailOptIn,
      ...formStateFromQuery.defaultValues,
    },
  });

  const { handleSubmit, setError } = methods;

  const [getRecaptchaToken] = useRecaptchaToken('SIGNUP');

  const submit = async (fields) => {
    const userTagFields = [];
    const signupProps = {
      emailOptIn: fields.emailOptIn,
    };
    setGeneralError(null);
    setInProgress(true);

    for (const { inputFields } of registerMethods.inputGroups) {
      for (const { isTag, key, queryMatchedTag } of inputFields) {
        // add special user tag when the input is prefilled from URL query parameter
        // https://magine.atlassian.net/browse/MDM-13332
        if (!!queryMatchedTag && formStateFromQuery.defaultValues[key]) {
          userTagFields.push(queryMatchedTag);
        }

        // save input with prop `isTag` value as user tag, usually for `picker` type
        // NB: make sure that all possible values are whitelisted
        if (isTag) {
          userTagFields.push(fields[key]);
          continue;
        }

        // gather fields required for signup flow - username/password/name
        switch (key) {
          case 'identity': {
            signupProps.username = fields[key];
            break;
          }
          case 'name': {
            signupProps.name = fields[key];
            break;
          }
          default: {
            signupProps[key] = fields[key];
            break;
          }
        }
      }
    }

    try {
      const reCaptchaToken = await getRecaptchaToken();
      await dispatch(authActions.signup({
        ...signupProps,
        locale,
        geoipCountry,
        signupInfo,
        ...(reCaptchaToken ? {
          captcha: {
            reCaptchaToken,
          },
        } : null),
      }));
      analytics.onSignupSuccess('email');
      await dispatch(userActions.getUser());

      if (userTagFields.length) {
        try {
          await dispatch(userActions.addUserTags(userTagFields));
        } catch (e) {
          logger.error(`Unable to save tags "${userTagFields}"`);
        }
      }

      if (isContentPagePaymentEnabled && isRedirectToEnabled) {
        // opens redirectTo or home page
        return dispatch(authActions.openInitialView());
      }

      if (isPurchaseFlowEnabled && purchase !== 'skip') {
        let nextQuery = { createAccount: null };

        if (Object.keys(redirectTo).length > 0) {
          nextQuery = getNextQuery(redirectTo);
        }

        return dispatch(authActions.openPurchaseFlow(nextQuery));
      }

      return dispatch(authActions.openInitialView());
    } catch (err) {
      // stop spinner only if signup failed
      setInProgress(false);

      if (err instanceof ApiErrors.IdentityUsedError) {
        setError('identity', {
          type: 'server',
          message: i18n.formatText('createAccount.identityIsUsed'),
          shouldFocus: true,
        });
        return null;
      }

      if (err instanceof ApiErrors.InvalidInputError) {
        if (err.fields.identity) {
          setError('identity', { message: err.fields.identity });
        }

        if (err.fields.password) {
          setError('accessKey', { message: err.fields.password });
        }
        return null;
      }

      if (err.response?.status === 409 || err.message?.includes('409')) {
        if (devicePlatform === PLATFORMS.MOVISTAR) {
          setIsAccountInUse({ status: true, username: signupProps.username });
          window.scrollTo(0, 0);
        }
      }

      if (err.response?.status === 403 || err.message?.includes('403')) {
        return setGeneralError(i18n.formatText('createAccount.errReCaptcha'));
      }

      logger.error('Failed to create account', err);

      return setGeneralError(i18n.formatText('createAccount.errFailed'));
    }
  };

  const handleUseAnotherAccount = () => {
    setIsAccountInUse(false);
    setGeneralError(null);
  };

  const platformDevice = i18n.formatText(devicePlatform === PLATFORMS.MOVISTAR ? 'decoder' : 'device');

  return (
    <PanelWrapper>
      <Panel textAlign="center" maxWidth="34rem">
        <Metadata
          title={registerMethods.inputGroups[0].title}
          description={registerMethods.inputGroups[0].subtitle}
        />

        {isAccountInUse.status && (
          <ErrorScreenOverlay
            username={isAccountInUse.username}
            handleUseAnotherAccount={handleUseAnotherAccount}
          />
        )}

        <Heading mb="medium">
          {registerMethods.inputGroups[0].title}
        </Heading>

        <Heading fontSize="medium" mb="medium" as="h2">
          {devicePlatform
            ? (
              <Text
                id="createAccount.subtitle.platform"
                values={{
                  brandName,
                  platform: PLATFORMS_NAME[devicePlatform?.toUpperCase()],
                  device: platformDevice,
                }}
              />
            )
            : registerMethods.inputGroups[0].subtitle}
        </Heading>

        <FormProvider {...methods}>
          <PanelContent as="form" onSubmit={handleSubmit(submit)} method="POST" noValidate>
            <Box
              column
              alignItems="stretch"
            >
              <InputFields
                inputGroups={registerMethods.inputGroups}
                disabledFields={formStateFromQuery.disabledFields}
              />

              {generalError && (
                <GeneralError>{generalError}</GeneralError>
              )}

              {showNewsletterCheckbox && (
                <Box py="fine" fullWidth>
                  <FormField
                    as={Checkbox}
                    name="emailOptIn"
                    label={{ id: 'createAccount.subscribeToNewsletter' }}
                    normalize={Boolean}
                  />
                </Box>
              )}
            </Box>

            <Box row my="medium" justifyContent="center">
              <PrimaryButton variant="brand" type="submit" showSpinner={inProgress} disabled={inProgress}>
                {registerMethods.doneLabel}
              </PrimaryButton>
            </Box>

            <Box mb="medium" textAlign="center">
              <TermsAndConditionsLink />
              <PrivacyPolicyLink />
            </Box>

            <RecaptchaDisclaimer />
          </PanelContent>
        </FormProvider>
      </Panel>
    </PanelWrapper>
  );
}

export async function initCreateAccountView(store) {
  await store.dispatch(offersActions.fetchPurchaseFlowOffers());
}

export default CreateAccountView;
