import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components/StyledSystem/Box';
import { Image } from 'components/Image/Image';
import { Text } from 'components';

import createComponent from 'styles/fela/createComponent';
import noConnectionIcon from './assets/no-connection.svg';
import sadSmiley from './assets/sad-smiley.png';
import errorIcon from './assets/tv.png';
import serverErrorIcon from './assets/serverErrorIcon.svg';
import epgErrorIcon from './assets/brokenTv.svg';
import ErrorActionButton from './ErrorActionButton';
import { ButtonType } from './constants';
import NoConnectionIcon from './NoConnectionIcon';

export const ERROR_ICONS = {
  NO_CONNECTION: {
    image: noConnectionIcon,
  },
  GENERAL_ERROR: {
    image: errorIcon,
  },
  NOT_FOUND: {
    image: sadSmiley,
    height: 134,
  },
  SERVER_ERROR: {
    image: serverErrorIcon,
  },
  EPG_ERROR: {
    image: epgErrorIcon,
    height: 144,
  },
};

const StyledNoConnectionIcon = createComponent(() => ({
  maxWidth: '100%',
  display: 'block',
}), NoConnectionIcon);

const ErrorPage = ({
  icon = ERROR_ICONS.GENERAL_ERROR,
  button = ButtonType.HOME,
  children,
  buttonProps,
}) => (
  <Box
    column
    justifyContent="center"
    width="100%"
  >
    {
      icon && (
        <Box mb="xxlarge">
          {icon === ERROR_ICONS.NO_CONNECTION ? (
            <StyledNoConnectionIcon />
          ) : (
            <Image src={icon.image} alt="Error" withMaxWidth height={icon.height} noPlaceholder />
          )}
        </Box>
      )
    }
    <Box mb="medium" textAlign="center" fullWidth>
      <Text
        fontSize="large"
        bold
        letterSpacing="1.3px"
        lineHeight="pageHeading"
      >
        {children}
      </Text>
    </Box>
    <ErrorActionButton type={button} {...buttonProps} />
  </Box>
);

ErrorPage.propTypes = {
  icon: PropTypes.oneOf([false, ...Object.values(ERROR_ICONS)]),
  button: PropTypes.oneOf(Object.values(ButtonType)),
  children: PropTypes.node,
  buttonProps: PropTypes.object,
};

export default ErrorPage;
