import buyOfferWithStripeQuery from 'queries/buyOfferWithStripe.graphql';
import buyOfferWithStripeOffSession from 'queries/buyOfferWithStripeOffSession.graphql';
import setupPaymentMethodInStripe from 'queries/setupPaymentMethodInStripe.graphql';
import {
  PAYMENT_PROVIDERS,
  STRIPE_STATUS,
} from 'utils/constants';
import logger from 'utils/logger';
import { ConflictError, DownstreamError } from 'utils/errors';
import { BillingPaymentMethod, IBillingPayment } from 'types';
import { apiNetworkRequest, graphqlRequest, AppAsyncAction } from './helpers';

export const GET_PAYMENT_METHOD = 'billing/GET_PAYMENT_METHOD';

interface GetPaymentMethodAction {
  type: typeof GET_PAYMENT_METHOD
  data: BillingPaymentMethod
}

export function getPaymentMethod(): AppAsyncAction {
  return async (dispatch) => {
    const result: BillingPaymentMethod = await dispatch(apiNetworkRequest('billing.getPaymentMethod'));

    dispatch({
      type: GET_PAYMENT_METHOD,
      data: result,
    });
  };
}

export const GET_PAYMENTS = 'billing/GET_PAYMENTS';

interface GetPaymentsAction {
  type: typeof GET_PAYMENTS
  data: IBillingPayment[]
}

export function getPayments(): AppAsyncAction {
  return async (dispatch) => {
    const result: IBillingPayment[] = await dispatch(apiNetworkRequest('billing.aggregatePayments'));

    dispatch({
      type: GET_PAYMENTS,
      data: result,
    });
  };
}

let stripePromise: Promise<any> | null = null; // Stripe API instance promise
async function getStripe(apiKey: string) {
  if (stripePromise) {
    return stripePromise;
  }

  if (!apiKey) {
    throw new Error('Api key for payment provider Stripe is missing');
  }

  // Load Stripe dynamically, otherwise it tracks users all the time
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { loadStripe } = require('@stripe/stripe-js');
  stripePromise = loadStripe(apiKey);

  return stripePromise;
}

function stripeUpdatePaymentMethod(returnPath: string): AppAsyncAction {
  return async (dispatch, getState) => {
    const stripe = await getStripe(getState().settings.features.payment?.provider?.apiKey);

    try {
      const {
        data,
      } = await dispatch(graphqlRequest(setupPaymentMethodInStripe, {
        successUrl: `${window.location.origin}${returnPath}`,
        cancelUrl: window.location.toString(), // just go back to the current page
      }));

      const {
        status,
        sessionId,
      } = data?.setupPaymentMethodInStripe || {};
      logger.info('setupPaymentMethodInStripe status', status);

      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        logger.error('Got error from Stripe', error);
        throw error;
      }
    } catch (err) {
      logger.error('Failed to update payment method with Stripe', err);
      throw err;
    }
  };
}

// eslint-disable-next-line max-params
export function stripePurchase(
  productId: string,
  promoCode: string | undefined,
  returnPath: string,
  useExistingCard: boolean,
  originalOfferId?: string,
): AppAsyncAction {
  return async (dispatch, getState) => {
    const isPromoCodeAllowed = getState().settings.features.payment?.allowPromoCodes;

    const stripe = await getStripe(getState().settings.features.payment?.provider?.apiKey);

    const query = useExistingCard ? buyOfferWithStripeOffSession : buyOfferWithStripeQuery;

    const commonVariables = {
      offerId: productId,
      originalOfferId,
      code: isPromoCodeAllowed && promoCode ? promoCode : undefined,
    };
    const newCardVariables = {
      ...commonVariables,
      successUrl: `${window.location.origin}${returnPath}`,
      cancelUrl: window.location.toString(), // just go back to the current page
    };
    const variables = useExistingCard ? commonVariables : newCardVariables;

    try {
      const response = await dispatch(graphqlRequest(query, variables));
      let { data: { buyOfferWithStripe } } = response;
      const { errors } = response;

      if (errors?.length && errors[0]?.extensions?.Conflict) {
        const { statusCode, details } = errors[0]?.extensions?.Conflict ?? {};
        throw new ConflictError(details, statusCode);
      } else if (errors?.length && errors[0]?.extensions?.DownstreamError) {
        const { statusCode, details } = errors[0]?.extensions?.DownstreamError ?? {};
        throw new DownstreamError(details, statusCode);
      }

      const { status } = buyOfferWithStripe;

      logger.info('buyOfferWithStripe status', status);

      // Bypass checkout or success payment using existing card
      if (status === STRIPE_STATUS.SUCCESS) {
        window.location.href = `${window.location.origin}${returnPath}`;
        return;
      }

      if (useExistingCard) {
        logger.error('Unable to process the payment using existing card, retrying with new one');

        const { data, errors: newCardErrors } = await dispatch(graphqlRequest(
          buyOfferWithStripeQuery,
          newCardVariables,
        ));

        if (newCardErrors?.length && newCardErrors[0]?.extensions?.Conflict) {
          const { statusCode, details } = newCardErrors[0].extensions.Conflict ?? {};
          throw new ConflictError(details, statusCode);
        } else if (newCardErrors?.length && newCardErrors[0]?.extensions?.DownstreamError) {
          const { statusCode, details } = newCardErrors[0].extensions.DownstreamError ?? {};
          throw new DownstreamError(details, statusCode);
        }

        buyOfferWithStripe = data.buyOfferWithStripe;
      }

      const { sessionId } = buyOfferWithStripe;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        logger.error('Got error from Stripe', error);
        throw error;
      }
    } catch (err) {
      logger.error('Failed to purchase offer with Stripe', err);
      throw err;
    }
  };
}

export function updatePaymentMethod(returnPath: string): AppAsyncAction {
  return (dispatch, getState) => {
    const providerType = getState().settings.features.payment?.provider?.type;

    switch (providerType) {
      case PAYMENT_PROVIDERS.STRIPE: {
        return dispatch(stripeUpdatePaymentMethod(returnPath));
      }

      default: {
        throw new Error(`Unexpected payment provider type: ${providerType}`);
      }
    }
  };
}

export type BillingActionTypes = GetPaymentMethodAction | GetPaymentsAction;
